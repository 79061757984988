<template>
  <div class="home page">
    <NavMenu>
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../assets/img/icon_1.png" alt="ico-title">
              <span>{{$t('home.title')}}</span>
            </div>
          </h2>
          <div class="user-info">
            <div class="user item">
              <img class="avater" src="../assets/img/icon_2.png" alt="avatar">
             <div class="info">
                <div class="username">{{userInfo?userInfo.nickname:''}}</div>
                <div>{{userInfo?userInfo.mobile:''}}</div>
              </div>
            </div>
            <div class="total item">
              <div>
                <span class="num">{{userInfo?userInfo.case_num:''}}</span>
                <span>{{$t('home.unit')}}</span>
              </div>
              <div>{{$t('home.bank')}}</div>
            </div>
            <div class="location item">
              <div>
                <img src="../assets/img/location.png" alt="location">
                <span class="location-name">{{userInfo?userInfo.near_login:''}}</span>
              </div>
              <div>{{$t('home.last')}}</div>
            </div>
          </div>
        </div>
       
				<div class="create-warp" @click="$router.push('/case/create')">
          <h2 class="title">
            <span>{{$t('home.create')}}</span>
            <img src="../assets/img/create.png" alt="icon">
          </h2>
          <div class="create-foot">
            {{$t('home.case')}}
          </div>
        </div>
        <div class="statistics">
          <div class="item-warp" @click="$router.push('/case/list')">
            <h2 class="title">
              <span>{{$t('home.bank')}}</span>
              <img src="../assets/img/icon_3.png" alt="icon">
            </h2>
            <div class="create-foot">
              {{$t('home.caseNum')}}{{userInfo?userInfo.case_num:''}}
            </div>
          </div>
          <div class="item-warp" @click="$router.push('/patient/list')">
            <h2 class="title">
              <span>{{$t('home.patient')}}</span>
              <img src="../assets/img/icon_4.png" alt="icon">
            </h2>
            <div class="create-foot">
              {{$t('home.patientsNum')}}{{userInfo?userInfo.patient_num:''}}
            </div>
          </div>
          <div class="item-warp" @click="$router.push('/change')">
            <h2 class="title">
              <span>{{$t('home.setting')}}</span>
              <img src="../assets/img/icon_4.png" alt="icon">
            </h2>
            <div class="create-foot" @click="goChangePwd">
              {{$t('home.pwd')}}
            </div>
          </div>
        </div>
      </div>
    </NavMenu>
  </div>
</template>

<script>
export default {
	data() {
	    return {
	      userInfo:{}
	    };
	  },
	  created() {},
	  mounted(){
		  this.getUser();
	  },
	  methods: {
	    getUser(){
        this.$api.userInfo().then(res=>{

          if(res.code){
            return this.userInfo = res.data;
          }
        }).catch(err=>{
          console.log(err)
        })
      },
 
      goChangePwd(){
        // this.$router.push({path:""})
      }
	  },
};
</script>
<style scoped>
.user-info {
  height: 150px;
  display: flex;
  color: #686868;
  font-size: 17px;
}
.user-info .item {
  height: 100%;
}
.user-info .user {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-info .user .avater {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-right: 20px;
}
.user-info .user .username {
  color: #000;
  /* font-weight: bold; */
  margin-bottom: 10px;
}
.user-info .user .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-info .total,
.user-info .location {
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-info .total .num {
  color: #000;
  font-size: 24px;
  /* font-weight: bold; */
  margin-right: 8px;
}
.user-info .location img {
  width: 18px;
  height: 22px;
  margin-right: 8px;
}
.user-info .location .location-name {
  color: #000;
  font-size: 22px;
  /* font-weight: bold; */
}
.create-warp,
.statistics .item-warp {
  width: 32%;
  height: 160px;
  background: #364284;
  margin: 40px 0 20px 0;
  color: #ffffff;

  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statistics .item-warp {
  background: #ffffff;
  color: #000;
}
.create-warp .title,
.statistics .item-warp .title {
  font-size: 22px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.create-warp .title img,
.statistics .item-warp .title img {
  width: 26px;
  height: 24px;
}
.create-warp .create-foot,
.statistics .item-warp .create-foot {
  font-size: 18px;
  padding: 14px 20px;
  border-top: 1px solid #f1f4f8;
}
.statistics .item-warp .create-foot {
  color: #686868;
}
.right-content{
  min-height: 0;
  height: auto;
}
</style>
